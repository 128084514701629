/* general layout */

body {
  font-family: 'San Francisco', 'Helvetica Neue', 'Lucida Grande', 'Segoe UI', Tahoma,
    'Microsoft Sans Serif', sans-serif;
  margin: 0;
  background-color: #f0f0f1;
}

.pageContainer {
  min-width: inherit;
  min-height: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: hidden;
  box-sizing: border-box;
}

.criteriaContainer {
  padding: 20px;
  width: 520px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fafafa;
  box-sizing: border-box;
}

.criteriaHeader {
  margin: 0px;
  font-size: 1.2em;
  font-weight: bold;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.itemContainer {
  background-color: #fafafa;
  box-sizing: border-box;
}

.itemHeader {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 1em;
  font-weight: bold;
  font-family: 'San Francisco', 'Helvetica Neue', 'Lucida Grande', 'Segoe UI', Tahoma,
    'Microsoft Sans Serif', sans-serif;
}

.defaultItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  color: #bdbdbd;
}

.defaultItemContainer .defaultMsg {
  width: 80%;
  text-align: center;
}

/* settings for printing */

@page {
  size: A4 landscape;
  margin-left: 0.5cm;
  margin-right: 0.5cm;
  margin-top: 0.5cm;
  margin-bottom: 0;
}

@media screen {
  .printonly,
  .printonly * {
    display: none;
  }

  .row {
    margin-top: 30px;
  }

  .resultContainer {
    width: 1200px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
  }
}

@media print {
  body {
    position: 'relative';
    width: 297mm;
    margin: 0px;
    padding: 0px;
  }

  .nonprintable,
  .nonprintable * {
    display: none;
  }

  .printonly,
  .printonly * {
    display: block;
  }

  .row {
    margin-top: 10px;
  }

  .resultContainer {
    position: absolute;
    width: 297mm;
    top: 0px;
    left: 0px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .page {
    position: relative;
    width: inherit;
    height: 210mm;
    overflow: hidden;
    page-break-inside: avoid;
    box-sizing: border-box;
  }

  .printFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: inherit;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    box-sizing: border-box;
  }
}
